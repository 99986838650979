
import { defineComponent, ref, reactive } from 'vue';
import store from '@/store'
import { mapGetters } from 'vuex'
import { onClickOutside } from '@vueuse/core'
import Counter from '../Counter/Counter.vue'
import SuiteNotifications from '../SuiteNotifications/SuiteNotifications.vue'
export default defineComponent({
  name : 'SuiteHeader',
  components:{
    Counter,
    SuiteNotifications,
  },
  props:{
    isCommunity:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      showMobileMenu: false
    }
  },
  computed: {
    ...mapGetters([
      'clientCreditsPlan',
      'clientData',
      'isAuthenticated',
      'notificationsAlert',
      'notificationsUnseen'
    ]),
  },
  methods:{
    closeSession(){
      if(this.isCommunity) {
        store.dispatch( 'USER_AUTH_LOGOUT' );
        window.location.href = process.env.VUE_APP_SUITE_URL
      }
      else{
        store.dispatch('closeSession')
        this.$router.push('/login')
      }
    },
    openNotifications(){
      let data = {
        idsToSee: this.notificationsAlert.map(function(alert: any){return alert.id})
      }
      store.dispatch('seeNotification', data)
    },
    goToSettings(){
      const name = this.isCommunity ? 'profileClient' : 'settings'
      this.$router.push( { name: name } )
    },
    goToHome(){
      if(this.isCommunity) window.location.href = process.env.VUE_APP_SUITE_URL
      else this. $router.push( { name: 'dashboard', } )
    }
  },
  setup() {
  const menuIcon = ref(null)
  const isDesktopMenuOpen = ref(false)
  onClickOutside(menuIcon, (event) => isDesktopMenuOpen.value = false)

  const notificationsIcon = ref(null)
  const isNotificationsOpen = ref(false)
  onClickOutside(notificationsIcon, (event) => isNotificationsOpen.value = false)

  const menuMobile = ref(null)
  const isMenuMobileOpen = ref(false)
  onClickOutside(menuMobile, (event) => isMenuMobileOpen.value = false)

  return { menuIcon, notificationsIcon, isDesktopMenuOpen, isNotificationsOpen, menuMobile, isMenuMobileOpen }
}
})
