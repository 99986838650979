import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "SubHeader" }
const _hoisted_2 = { class: "SubHeader--BreadCrumbs" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "fas fa-angle-right" }
const _hoisted_6 = { class: "SubHeader--Nav" }
const _hoisted_7 = {
  key: 5,
  class: "SubHeader--Space"
}
const _hoisted_8 = {
  key: 8,
  class: "SubHeader--NavItem"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_notifications_panel = _resolveComponent("notifications-panel")!
  const _component_popup_panel = _resolveComponent("popup-panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "SubHeader--Back",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBack('dashboard')))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("svg", {
          width: "12",
          height: "10",
          viewBox: "0 0 12 10",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("path", {
            d: "M0.75 5.00002H11.25M4.83333 9.08335L0.75 5.00002L4.83333 9.08335ZM0.75 5.00002L4.83333 0.916687L0.75 5.00002Z",
            stroke: "#1F1F1F",
            "stroke-width": "1.16667",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          })
        ], -1)
      ])),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadCrumbs(), (bc, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "SubHeader--BreadCrumb",
          onClick: ($event: any) => (_ctx.goBack(bc.link))
        }, [
          _createElementVNode("p", {
            innerHTML: bc.name
          }, null, 8, _hoisted_4),
          _withDirectives(_createElementVNode("i", _hoisted_5, null, 512), [
            [_vShow, index < _ctx.breadCrumbs().length - 1]
          ])
        ], 8, _hoisted_3))
      }), 256))
    ]),
    _createElementVNode("div", _hoisted_6, [
      (!_ctx.isObservator)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: { name: 'welcome', params: { origin: _ctx.origin } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('welcome')), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: { name: 'activities', params: { origin: _ctx.origin } },
        class: "SubHeader--NavItem"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('activities')) + " ", 1),
          (_ctx.communityStatus.moderatorUnseendComments > 0)
            ? (_openBlock(), _createBlock(_component_Badge, {
                key: 0,
                count: _ctx.communityStatus.moderatorUnseendComments
              }, null, 8, ["count"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["to"]),
      (!_ctx.isObservator)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: { name: 'members', params: { origin: _ctx.origin } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('members')), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      (!_ctx.communityInfo.hideGamification && !_ctx.isObservator)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 2,
            to: { name: _ctx.communityInfo.redeemUrl != undefined ? _ctx.communityInfo.redeemUrl : 'redeem', params: { origin: _ctx.origin } }
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('redeem_header')), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      (_ctx.isCommunityMainAdmin)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 3,
            to: { name: 'moderator-admin'}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navbar_moderator_admin')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isCommunityMainAdmin)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 4,
            to: { name: 'profileClient'}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navbar_personalization')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.isObservator)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7))
        : _createCommentVNode("", true),
      (!_ctx.isObservator)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 6,
            to: { name: 'search' , params: { origin: _ctx.origin } }
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("i", { class: "fas fa-search" }, null, -1)
            ])),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      (_ctx.isModerator && _ctx.communityInfo.status != 'REVISION')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 7,
            to: { name: 'email' , params: { origin: _ctx.origin } }
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("i", { class: "far fa-envelope" }, null, -1)
            ])),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: { name: 'chat', params: { origin: _ctx.origin } },
        class: "SubHeader--NavItem"
      }, {
        default: _withCtx(() => [
          _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fa fa-comments" }, null, -1)),
          (_ctx.communityTotalNotSeen)
            ? (_openBlock(), _createBlock(_component_Badge, {
                key: 0,
                count: _ctx.communityTotalNotSeen
              }, null, 8, ["count"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["to"]),
      (!_ctx.isObservator)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", {
              class: "Notifications__ButtonArea",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openNotificationsPanel && _ctx.openNotificationsPanel(...args)))
            }),
            _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fa fa-bell" }, null, -1)),
            (_ctx.unseenNotifCount)
              ? (_openBlock(), _createBlock(_component_Badge, {
                  key: 0,
                  count: _ctx.unseenNotifCount
                }, null, 8, ["count"]))
              : _createCommentVNode("", true),
            _createVNode(_component_popup_panel, {
              show: _ctx.showNotifications,
              onClickedOutside: _ctx.closeNotificationsPanel
            }, {
              default: _withCtx(() => [
                _createVNode(_component_notifications_panel, {
                  data: _ctx.notifications,
                  onClosePanel: _ctx.closeNotificationsPanel
                }, null, 8, ["data", "onClosePanel"])
              ]),
              _: 1
            }, 8, ["show", "onClickedOutside"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}