<template lang="html">
  <div class="ChatEL">
    <Chat
      ref="chat"
      :messages="chatMessages"
      :isModerator="isModerator"
      :photoUploadUrl="photoUploadUrl"
      :disableSubmit="disableSubmit"
      v-on:send-message="handleSendMessage($event)"
      v-on:enlarge-pic="$emit('enlarge-pic', $event)"
      ></Chat>
  </div>
</template>

<script>
import Chat from '~/components/Chat';

export default {
  name: 'ChatEL',

  components: {
    Chat,
  },
  props: {
    chatMessages:{
      type: Array,
      default: [],
    },
    isModerator: {
      type: Boolean,
      default: false,
    },

    photoUploadUrl: {
      type: String,
      default: ''
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
  },

  async mounted(){

  },
  methods: {
    handleSendMessage: async function(e){
      this.$emit('send-message',e)
    },

    adjustMessagesScroll: function(){
      this.$refs.chat.adjustMessagesScroll()
    },
  }
}
</script>

<style lang="scss">
.ChatEL{
  width: 400px;
  z-index: 2;
  position: absolute;
  // top: 60px;
  right: 0;
  &--Moderator{
    position: initial;
    width:100%;
    .Chat{
      width: 100%;
      height: 100%!important;
      align-items: center;
    }
    .Chat__Messages{
      width: 70%;
    }
    .Chat__Controls{
      width: 70%;
      position: relative;
      button{
        float: none;
        position:absolute;
        right: .5rem;
        bottom: 1rem;
      }
      textarea{
        height: 80px;
      }
    }
  }
  .Chat{
    height: 400px;
    border-radius:0;
    box-shadow: none;
    border: 1px solid #f9f9f9;
    button{
      border-radius: 50px;
      padding: .3em 1.8em;
      font-size: 14px;
      font-family:'Open Sans', Helvetica, Arial, sans-serif;
    }
    textarea{
      padding: 5px 1em 2em 5px;
      font-size: 0.9em;
    }
    &__Messages{
      background: #f9f9f9!important;
    }
    &__Message{
      margin-top: 10px;
      font-size:15px;
      background: white;
      &::before{
        border-right-color: white;
        border-left-color: white;
      }
      &--Moderator{
        background: white;
        color: gray;
      }
      .Message__Content{
        font-weight: 600;
        color: black;
      }
    }
  }
}
</style>
