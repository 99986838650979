<template>
    <div>
      <ul class="Notifications__List" v-if="values.length">
        <li class="Notifications__List--Item" v-for="n in values" :key="n.id">
          <notification-item
            :text="n.text.translated"
            :date="n.creationDate"
            :type="n.type.replace('EL_MOD', 'EL')"
            :seen="n.seen"
            @open-notification="
              openNewScreen( $event, n.activityId, n.communityId, n.commentId, n.commentPage )
            "
          ></notification-item>
        </li>
        <li class="Notifications__List--Footer" @click="openAllNotifications">
          <h5 role="button">{{$t('notification_see_previous')}}</h5>
        </li>
      </ul>
      <ul class="Notifications__List" v-if="!values.length">
        <li class="Notifications__List--Item">
          <notification-item
            :text="$t('notification_up_to_date')"
            type="none"
          ></notification-item>
        </li>
        <li class="Notifications__List--Footer" @click="openAllNotifications">
          <h5 role="button">{{$t('notification_see_previous')}}</h5>
        </li>
      </ul>
    </div>
</template>

<script>
  import NotificationItem from '@/components/NotificationItem.vue';
  import {
    MEDAL, ANSWER, ACT_REMINDER, ACT_LAUNCHED, COM_LAUNCHED,EL_MOD_NEW_ANSWER_TO_COMMENT, WL_NEW_ANSWER_TO_COMMENT, WL_MOD_NEW_ANSWER_TO_COMMENT, MENTION,WL_MOD_MENTIONED_IN_COMMENT
  } from '~/constants/constants.type'

  export default {
    name: 'NotificationsPanel',

    components: {
      NotificationItem
    },

    props: {
      show: {
        type    : Boolean,
        default : false
      },
      data: {
        type    : Array,
        default : null
      }
    },

    computed: {
      mainClass ( ) {
        return this.show ? 'NotificationsPanel' : 'NotificationsPanel hide'
      },

      values ( ) {
        return this.data ? this.data.filter( ( d, i ) => i < 5 ) : [];
      }
    },

    data ( ) {
      return {
        MEDAL, ANSWER, ACT_REMINDER, ACT_LAUNCHED, COM_LAUNCHED,
      }
    },

    methods: {
        navigateTo(name, arg) {
      this.$emit('close-panel');

      const params = { origin: this.$route.params.origin }
      if (arg)
        params.id = arg;
      this.$router.push({ name, params });
    },

    openAllNotifications() {
      this.$emit('clear-all-notifications');
      this.navigateTo('notifications');
    },

        async scrollToComment(commentId) {
        console.log(commentId)
      await this.$nextTick(); 
      const commentElement = document.querySelector(`.comment_${commentId}`);
      if (commentElement) {
        commentElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
      commentElement.style.backgroundColor = 'rgba(255, 215, 0, 0.3)'; 
      commentElement.style.padding = '5px'; 
      commentElement.style.borderRadius = '5px'; 
          commentElement.style.transition = 'all 0.5s ease'; 
        setTimeout(() => {
          commentElement.style.backgroundColor = 'transparent'; 
          commentElement.style.padding = '0'; 
          commentElement.style.borderRadius = '0'; 
        }, 5000);
        }
    },

        openNewScreen(event, act, cmty, cmnt, cmntP) {
        console.log("openNewScreen  cmntP:", cmntP)
        console.log("openNewScreen  cmnt:", cmnt)
        console.log("openNewScreen  cmty:", cmty)
        console.log("openNewScreen  act:", act)
        console.log("openNewScreen  event:", event)
        
      const { type } = event;
      const currentRoute = this.$route;
      const isCurrentRoute = (name, params) => {
        return currentRoute.name === name &&
               (!params || Object.keys(params).every(key => currentRoute.params[key] === params[key]));
      };

      if (type === this.MEDAL) {
        if (!isCurrentRoute('profile')) {
          this.navigateTo('profile');
        }
      } else if (type === COM_LAUNCHED) {
        if (!isCurrentRoute('welcome')) {
          this.navigateTo('welcome');
        }
      } else if (type === ACT_LAUNCHED || type === ACT_REMINDER) {
        if (!isCurrentRoute('activity.info', { id: act })) {
          this.navigateTo('activity.info', act);
        }
      } else if (type === MENTION || type === ANSWER  || type === WL_MOD_MENTIONED_IN_COMMENT  || type === EL_MOD_NEW_ANSWER_TO_COMMENT || type === WL_NEW_ANSWER_TO_COMMENT || type === WL_MOD_NEW_ANSWER_TO_COMMENT) {
         this.$router.push({ name: 'activity.info', params: {notificationCommentId: cmnt, notificationCommentPage:cmntP, id:act }}).then(() => {
            this.scrollToComment(cmnt);
          });
        
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .Notifications__List {
    list-style-type   : none;

    &--Item {
      padding         : 1rem;
      border-bottom   : 1px solid lightgray;
      max-width       : 500px;
    }

    &--Footer {
      background      : #fefefe;
      color           : #999;
      padding         : .8rem 0;
      text-align      : center;
      text-transform  : uppercase;
      border          : 1px solid #eaeaea;
      box-shadow      : inset 0px 10px 17px -10px rgba(0,0,0,0.2);
      cursor          : pointer;
    }
  }
</style>
