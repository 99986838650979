<template>
  <BlankLayout class="Panel" :class="{[routeClass]:true,'hide-header':hideHeader, 'suite-logged':isLoggedFromSuite}">
    <SuiteHeader class="SuiteHeader" isCommunity v-if="!hideHeader && isLoggedFromSuite"/>
    <SubHeader class="SuiteSubHeader" v-if="!hideHeader && isLoggedFromSuite"/>
    <Header v-if="!hideHeader && !isLoggedFromSuite"/>
    <router-view ref="MainRouterView" v-on:hide-header="hideHeaderItem($event)"/>
  </BlankLayout>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
import BlankLayout from '@/layouts/BlankLayout.vue';
import Header from '@/layouts/partials/Header.vue';
import SuiteHeader from '~/components/SuiteHeader/SuiteHeader.vue'
import SubHeader from '@/layouts/partials/SubHeader.vue';
export default {
  name: 'PanelView',

  components: {
    BlankLayout,
    Header,
    SuiteHeader,
    SubHeader
  },

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'isClient',
      'isLoggedFromSuite'
    ]),

    routeClass() {
      return 'Panel--' + this.$route.name.replace('.', '-');
    },
  },

  data(){
    return{
      hideHeader: false
    }
  },

  methods:{
    hideHeaderItem(ev){
      this.hideHeader = ev
    }
  }
}
</script>

<style scoped lang="scss">
  .SuiteHeader{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9997;
  }
  .SuiteSubHeader{
    position: fixed;
    z-index: 9996;
    top: 60px;
    left: 0;
    right: 0;
  }
  .Panel {
    min-height: 100%;
    padding-top: 70px;
    background: linear-gradient(269.66deg, rgba(0, 0, 0, 0.4) 3.24%, transparent 96.38%);
    &.suite-logged{
      padding-top: 105px;
    }
    &.hide-header{
      padding-top: 0px!important;
      padding-bottom: 0px!important;
    }
  }
  @media (max-width: 767px) {
    .Panel{
      padding-top: 60px;  
    }
    .Panel.suite-logged{
      padding-top: 60px;
    }
    .SuiteSubHeader{
      display: none;
    }
  }
</style>
<style lang="scss">
.Panel.suite-logged{
  .GradientBack{
    height: calc(100vh - 105px);
  }
  .activitiesPage .activities__List{
    height: calc(100vh - 192px);
  }
}
</style>
