<template lang="html">
  <i class="fa Icon" :class="iconClass"></i>
</template>

<script>
export default {
  name: 'Icon',

  props: {
    name: {
      type: String,
      default: null
    }
  },

  computed: {
    iconClass: function () {
      return 'fa-' + this.name
    },
  },
}
</script>

<style scoped lang="scss">
.Icon {
  text-align: center;
  padding: .4em;
  font-size: 1.3em;
}
</style>
