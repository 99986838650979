
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
export default defineComponent({
  name : 'SuiteNotifications',
  computed: {
    ...mapGetters([
      'notificationsAlert'
    ]),
  }
})
