import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/img/logo-wat.svg'
import _imports_1 from '../../assets/img/wat-icons-svg/credits-icon.svg'
import _imports_2 from '../../assets/img/wat-icons-svg/menu-icon.svg'


const _hoisted_1 = { class: "Header" }
const _hoisted_2 = { class: "Header--Info hideOnMobile" }
const _hoisted_3 = { class: "Header--Item Header--ItemHover" }
const _hoisted_4 = { class: "Header--CreditsInfo" }
const _hoisted_5 = { class: "Header--CreditsInfoContent" }
const _hoisted_6 = { class: "Header--CreditsInfoTotal" }
const _hoisted_7 = { class: "info" }
const _hoisted_8 = ["data-content"]
const _hoisted_9 = { class: "Header--Profile" }
const _hoisted_10 = { class: "Header--CompanyLogo" }
const _hoisted_11 = {
  key: 1,
  class: "far fa-image"
}
const _hoisted_12 = { key: 2 }
const _hoisted_13 = {
  class: "HeaderMobile showOnMobile",
  ref: "menuMobile"
}
const _hoisted_14 = ["checked"]
const _hoisted_15 = { class: "HeaderMobile--Menu" }
const _hoisted_16 = { class: "HeaderMobile--Gradient" }
const _hoisted_17 = { class: "Header--CompanyLogo" }
const _hoisted_18 = {
  key: 1,
  class: "far fa-image"
}
const _hoisted_19 = { key: 2 }
const _hoisted_20 = { class: "HeaderMobile--Gradient" }
const _hoisted_21 = { class: "Header--Item" }
const _hoisted_22 = ["data-content"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Counter = _resolveComponent("Counter")!
  const _component_SuiteNotifications = _resolveComponent("SuiteNotifications")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "Header--WatLogo",
      src: _imports_0,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToHome()))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Counter, {
          count: _ctx.clientCreditsPlan.creditsAvailable
        }, null, 8, ["count"]),
        _cache[11] || (_cache[11] = _createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('suite_credits_available')) + " ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.clientCreditsPlan.creditsAvailable), 1)
              ]),
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('suite_credits_no_available')) + " ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.clientCreditsPlan.creditsBlocked), 1)
              ])
            ]),
            _createElementVNode("p", _hoisted_7, [
              _cache[10] || (_cache[10] = _createElementVNode("span", { class: "icon-info-icon" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('suite_credits_info')), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: "Header--Item Header--Notifications",
        onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.isNotificationsOpen=!_ctx.isNotificationsOpen;_ctx.openNotifications()}),
        ref: "notificationsIcon"
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["fas fa-bell Badge", {'Badge--Greater':_ctx.notificationsUnseen > 9, 'Badge--Zero':_ctx.notificationsUnseen == 0}]),
          "data-content": _ctx.notificationsUnseen
        }, null, 10, _hoisted_8),
        _withDirectives(_createVNode(_component_SuiteNotifications, null, null, 512), [
          [_vShow, _ctx.isNotificationsOpen]
        ])
      ], 512),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          (_ctx.clientData.companyLogoUrl != null)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle({ backgroundImage: 'url(\'' + _ctx.clientData.companyLogoUrl + '\')' })
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.clientData.companyLogoUrl == null)
            ? (_openBlock(), _createElementBlock("i", _hoisted_11))
            : _createCommentVNode("", true),
          (_ctx.clientData.companyLogoUrl == null)
            ? (_openBlock(), _createElementBlock("p", _hoisted_12, "Tu logo"))
            : _createCommentVNode("", true)
        ]),
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "Header--Separator" }, null, -1)),
        _createElementVNode("div", {
          class: "Header--ProfileImage",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToSettings()))
        }, [
          _createElementVNode("div", {
            style: _normalizeStyle({ backgroundImage: 'url(\'' + _ctx.clientData.picture + '\')' })
          }, null, 4),
          _createElementVNode("p", null, _toDisplayString(_ctx.clientData.contactName), 1)
        ]),
        _createElementVNode("img", {
          class: "Header--OpenMenu",
          src: _imports_2,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDesktopMenuOpen=!_ctx.isDesktopMenuOpen)),
          ref: "menuIcon"
        }, null, 512),
        _createElementVNode("div", {
          class: _normalizeClass(["Header--DesktopMenu", {'show':_ctx.isDesktopMenuOpen}])
        }, [
          _createElementVNode("div", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.closeSession()))
          }, _cache[12] || (_cache[12] = [
            _createElementVNode("i", { class: "fas fa-sign-out-alt" }, null, -1),
            _createTextVNode(" Cerrar sesión")
          ])),
          _createElementVNode("div", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push( { name: 'settings' } )))
          }, _cache[13] || (_cache[13] = [
            _createElementVNode("i", { class: "fas fa-cog" }, null, -1),
            _createTextVNode("Tu cuenta")
          ]))
        ], 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", {
        class: "HeaderMobile--bars",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isMenuMobileOpen = !_ctx.isMenuMobileOpen))
      }, [
        _createElementVNode("input", {
          type: "checkbox",
          checked: _ctx.isMenuMobileOpen
        }, null, 8, _hoisted_14),
        _cache[15] || (_cache[15] = _createElementVNode("span", null, null, -1)),
        _cache[16] || (_cache[16] = _createElementVNode("span", null, null, -1)),
        _cache[17] || (_cache[17] = _createElementVNode("span", null, null, -1))
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            (_ctx.clientData.companyLogoUrl != null)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  style: _normalizeStyle({ backgroundImage: 'url(\'' + _ctx.clientData.companyLogoUrl + '\')' })
                }, null, 4))
              : _createCommentVNode("", true),
            (_ctx.clientData.companyLogoUrl == null)
              ? (_openBlock(), _createElementBlock("i", _hoisted_18))
              : _createCommentVNode("", true),
            (_ctx.clientData.companyLogoUrl == null)
              ? (_openBlock(), _createElementBlock("p", _hoisted_19, "Tu logo"))
              : _createCommentVNode("", true)
          ]),
          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "Header--Separator" }, null, -1)),
          _createElementVNode("div", {
            class: "Header--ProfileImage",
            onClick: _cache[7] || (_cache[7] = ($event: any) => {_ctx.goToSettings();_ctx.isMenuMobileOpen=false})
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle({ backgroundImage: 'url(\'' + _ctx.clientData.picture + '\')' })
            }, null, 4),
            _createElementVNode("p", null, _toDisplayString(_ctx.clientData.contactName), 1)
          ])
        ]),
        _cache[23] || (_cache[23] = _createElementVNode("div", { class: "HeaderMobile--Separator" }, null, -1)),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", {
            class: "Header--Item",
            onClick: _cache[8] || (_cache[8] = ($event: any) => {_ctx.goToTransactions();_ctx.isMenuMobileOpen=false})
          }, [
            _createVNode(_component_Counter, {
              count: _ctx.clientCreditsPlan.creditsAvailable
            }, null, 8, ["count"]),
            _cache[19] || (_cache[19] = _createElementVNode("img", {
              src: _imports_1,
              alt: ""
            }, null, -1)),
            _cache[20] || (_cache[20] = _createElementVNode("p", null, "Creditos", -1))
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("i", {
              class: _normalizeClass(["fas fa-bell Badge", {'Badge--Greater':_ctx.notificationsUnseen > 9, 'Badge--Zero':_ctx.notificationsUnseen == 0}]),
              "data-content": _ctx.notificationsUnseen
            }, null, 10, _hoisted_22),
            _cache[21] || (_cache[21] = _createElementVNode("p", null, "Notificaciones", -1))
          ]),
          _createElementVNode("div", {
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.closeSession()))
          }, _cache[22] || (_cache[22] = [
            _createElementVNode("i", { class: "fas fa-sign-out-alt" }, null, -1),
            _createTextVNode(" Cerrar sesión")
          ]))
        ])
      ], 512), [
        [_vShow, _ctx.isMenuMobileOpen]
      ])
    ], 512)
  ]))
}