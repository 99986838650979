
import { defineComponent, ref, reactive } from 'vue';
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import {
  NOTIFICATIONS_CLEAR_ID,
  NOTIFICATIONS_FETCH_STATE
} from '@/store/notifications/notifications.module';
import Badge from '~/components/Badge/Badge.vue'
import PopupPanel from '@/components/PopupPanel.vue';
import NotificationsPanel from '@/components/NotificationsPanel.vue';
import store from '@/store'
export default defineComponent({
  name : 'SubHeader',
  components:{
    Badge,
    PopupPanel,
    NotificationsPanel
  },
  computed: {
    ...mapGetters([
      'theme',
      'origin',
      'communityStatus',
      'communityInfo',
      'isCommunityMainAdmin',
      'isModerator',
      'communityCurrentActivity',
      'communityTotalNotSeen',
      'notifications',
      'unseenNotifCount',
      'isObservator'
    ])
  },
  data(){
    return{
      showNotifications: false
    }
  },
  methods:{
    breadCrumbs(){
      let breadCrumbs = []
      const theme = this.theme
      const routerName: any = this.$router.currentRoute.value.name ? this.$router.currentRoute.value.name : ''
      breadCrumbs.push({name:'<i class="fas fa-home"></i>', link:'dashboard'})
      breadCrumbs.push({name:this.theme.title})
      if(routerName == 'activity.info') breadCrumbs.push({name:this.$t('activity') + ' ' + this.communityCurrentActivity.identifier})
      if(routerName == 'members') breadCrumbs.push({name:this.$t('members')})
      if(routerName == 'redeem') breadCrumbs.push({name:this.$t('redeem_header')})
      if(routerName == 'chat') breadCrumbs.push({name:this.$t('chat')})
      if(routerName == 'notifications') breadCrumbs.push({name:this.$t('notifications')})

      return breadCrumbs
    },
    goBack(link: any){
      if(link == 'dashboard') window.location.href = process.env.VUE_APP_SUITE_URL
    },
    openNotificationsPanel ( ) {
      if ( !this.showNotifications ) {
        this.showNotifications = true;
        this.fetchNotifications( );
      }
    },

    closeNotificationsPanel ( ) {
      this.showNotifications = false;
      this.fetchNotifications( );
    },

    fetchNotifications ( ) {
      if ( this.showNotifications ) {
        const last5 = this.notifications
          ? this.notifications.filter( ( d: any, i: any ) => i < 5 ).map( (d: any) => d.id )
          : [];
        store.dispatch( NOTIFICATIONS_CLEAR_ID, { ids: last5, isClient: true, hideSpinner: false } )
      }
    },
  }
})
