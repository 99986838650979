<template>
  <div
    :style="!seen && 'border-left: 2px solid red'"
    class="NotificationItem"
    @click="handleClick"
  >
    <div class="NotificationItem__Icon" v-if="type!=='none'">
      <img    v-if="type===ANSWER"        src="@/assets/img/opinionIcon.png">
      <img    v-if="type===ACT_REMINDER"  src="@/assets/img/blogIcon.png"   >
      <img    v-if="type===ACT_LAUNCHED"  src="@/assets/img/blogIcon.png"   >
      <img    v-if="type===COM_LAUNCHED"  src="@/assets/img/pollIcon.png"   >
      <Trophy v-if="type===MEDAL"         type="gold" style="width: 50px"   >
      </Trophy>
    </div>
    <div class="NotificationItem__Text">
      <span class="title" v-text="text"></span>
      <small class="date" v-text="formatDate"></small>
    </div>
  </div>
</template>

<script>
  import Trophy from '@/components/Trophy.vue';
  import {
    MEDAL, ANSWER, ACT_REMINDER, ACT_LAUNCHED, COM_LAUNCHED
  } from '~/constants/constants.type'

  export default {

    name: 'NotificationItem',

    props: {
      text: {
        type    : String,
        default : null
      },
      date: {
        type    : String,
        default : null
      },
      type: {
        type    : String,
        default : null
      },
      seen: {
        type    : Boolean,
        default : true
      }
    },

    data ( ) {
      return {
        MEDAL, ANSWER, ACT_REMINDER, ACT_LAUNCHED, COM_LAUNCHED
      }
    },

    components: {
      Trophy
    },

    methods: {
      handleClick ( ) {
        this.$emit( 'open-notification', { type: this.type } );
      }
    },

    computed: {
      formatDate ( ) {
        return this.date
          ? new Date( this.date ).toLocaleString( 'es-ES' )
          : '';
      }
    }

  }
</script>

<style scoped lang="scss">
  .NotificationItem {
    display           : flex;
    align-items       : center;
    cursor            : pointer;

    &__Text {
      width           : 100%;
      padding-left    : 1rem;
      font-size       : .8rem;
      transition      : 200ms;

      &:hover {
        padding-left  : 1.2rem;

        & > .title {
          color       : #555;
        }
      }

      .title {
        font-weight   : bold;
        color         : #888;
        transition    : 200ms;
      }

      .date {
        display       : block;
        color         : #bfbfbf;
        padding-top   : .3rem;
        font-size     : .8rem;
      }
    }
  }
</style>
