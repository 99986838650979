
import { defineComponent, getCurrentInstance } from 'vue';
import {numberCounter} from '../../utils/numberCounter'
export default defineComponent({
  name : 'Counter',
  props:{
    count:{
      type:Number,
      default:0
    },
  },
  watch: {
    count(newValue, oldValue) {
      numberCounter(this.$refs.counter, 0, this.count, 1000);
    }
  },
  async mounted(){
    numberCounter(this.$refs.counter, 0, this.count, 1000);
  }
})
