<template lang="html">
  <div class="Trophy">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-289 412.9 17 17">
      <path :fill="color[0]" d="M-283.3 416.6c-.3-.5-.7-1-1.1-1.4-.4-.5-.9-1-1.4-1.4h4.1c.5.4 1 .9 1.4 1.3.4.4.8.8 1.2 1.3.2.2.3.4.5.7h-4.1c-.2-.2-.4-.3-.6-.5z" />
      <path :fill="color[1]" d="M-279.1 416.4c-.4-.5-.8-.9-1.2-1.3.4-.4.8-.9 1.2-1.3h3.8c-.8.9-1.6 1.8-2.5 2.6h-1.3z" />
      <path :fill="color[2]" d="M-283.3 416.6c.2.2.4.3.6.5.1.2.3.5.4.4 1.3-.4 2.6-.2 4 0 .2.1.4.1.6.2.6.6 1.4 1.2 1.9 1.9 2.5 3.3.9 8.7-4.3 9.3-3.2.3-6.2-2.4-6.2-5.5 0-2.1.7-3.9 2.4-5.1.3-.2.6-.5.5-1 0-.2.1-.4.1-.7z" />
      <path :fill="color[3]" d="M-277.8 417.8c-.2-.1-.4-.1-.6-.2-.1-.2-.2-.4-.2-.5-.2-.2-.3-.4-.5-.7h1.4c-.1.4-.1.9-.1 1.4z" />
      <path :fill="color[4]" d="M-280.5 418.8c2.4-.2 4.4 2 4.5 4.4.1 2.3-2.2 4.4-4.6 4.4s-4.7-2.2-4.5-4.5c.3-2.5 2.3-4.5 4.6-4.3z" />
      <path :fill="color[5]" d="M-280.5 419.2l1.2 2.4 2.5.3-1.8 1.8.4 2.6-2.3-1.2-2.3 1.2.4-2.6-1.8-1.8 2.6-.3 1.1-2.4z" />
      <text class="value" x="-280.5" y="425.3">{{ value }}</text>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Trophy',

  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: false,
    }
  },

  computed: {
    color ( ) {
      if ( this.type === 'gold'   )
        return [ '#fe735b', '#f05641', '#ffd251', '#ffd251', '#ffb741', '#ffd251' ];
      if ( this.type === 'silver' )
        return [ '#fe735b', '#f05641', '#cfd0d1', '#cfd0d1', '#a19b9a', '#cfd0d1' ];
      if ( /bronz|ce/.test( this.type ) )
        return [ '#fe7058', '#f05540', '#d08d5b', '#9d6f4b', '#9d6f4b', '#ce8b5a' ];
    }
  }
}
</script>

<style scoped lang="scss">
  .Trophy {
    width           : 100%;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;

    img {
      width         : 100%;
      height        : 100%;
    }

    .value {
      font-size     : 6px;
      font-weight   : bold;
      fill          : #333;
      text-anchor   : middle;
    }
  }
</style>