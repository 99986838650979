<template>
  <div :class="mainClass">
    <div class="PopupPanel" v-if="show" ref="notifPanel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { onClickOutside } from '@vueuse/core'
import { defineComponent, ref } from 'vue';

  export default defineComponent({
    name: 'PopupPanel',

    computed: {
      mainClass ( ) {
        return this.show ? 'PanelContainer' : 'PanelContainer hide'
      }
    },

    props: {
      show: {
        type    : Boolean,
        default : false
      },
    },

    methods: {
      closeNotifPanel ( event ) {
        this.$emit( 'clicked-outside' );
      },
    },
    setup(props, { emit }) {
      const notifPanel = ref(null)
      onClickOutside(notifPanel, (event) => emit( 'clicked-outside' ))

      return { notifPanel }
    }
  })
</script>

<style scoped lang="scss">
  .PanelContainer {
    opacity               : 1;
    position              : absolute;
    z-index               : 999;
    top                   : calc( 100% + 3px );
    right                 : 0;
    display               : flex;
    flex-direction        : column;
    align-items           : flex-end;
    background            : transparent;
    transition            : opacity 300ms;

    &:before {
    content               : '';
    position              : absolute;
    z-index               : 999;
    width                 : 0;
    height                : 0;
    left                  : -40px;
    top                   : -22px;
    display               : block;
    border                : 10px outset transparent;
    border-bottom         : 15px solid white;
    filter                : drop-shadow( 0px -4px 3px #eaeaea );
    @media (max-width:767px) {
      display: none;
    }
    }

    .PopupPanel {
      position            : absolute;
      z-index             : 998;
      width               : 320px;
      background          : white;
      border              : 1px solid #eaeaea;
      filter              : drop-shadow( 0px 0px 3px #eaeaea );
    }
  }

  .hide {
    opacity               : 0 !important;
  }
</style>
