import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "Notifications" }
const _hoisted_2 = { class: "Notifications--Scroll" }
const _hoisted_3 = { class: "Notifications--ElementContainer" }
const _hoisted_4 = {
  key: 0,
  class: "Notifications--Empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationsAlert, (notification) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["Notifications--Element", {'unseen':!notification.seen}])
          }, [
            _createElementVNode("p", null, _toDisplayString(notification.text.translated), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$filters.formatDate(notification.creationDate,'DD/MM/YYYY, HH:MM')), 1)
          ], 2)
        ]))
      }), 256)),
      (_ctx.notificationsAlert == undefined || _ctx.notificationsAlert.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
            _createElementVNode("span", { class: "icon-info-icon" }, null, -1),
            _createElementVNode("p", null, "No tienes notificaciones pendientes", -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}