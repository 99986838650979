<template>
  <div class="wrapper">
    <div class="container-fluid">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlankLayout'
}
</script>

<style scoped lang="scss">
.wrapper, .container-fluid {
  height: fit-content;
  min-height: 100%;
  overflow: auto;
}
</style>
